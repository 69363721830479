* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.05em;
}

body ul {
  list-style: none;
}

body {
  background: #F6F6F6;
}
